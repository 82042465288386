import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1cb147fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "copy-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NTooltip"], {
    class: "tooltip copy-tooltip",
    trigger: $props.trigger,
    showArrow: false
  }, {
    trigger: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString($props.copiedValue), 1),
        _createVNode($setup["NButton"], {
          class: "text-button copy-button",
          onClick: $setup.onCopy
        }, {
          default: _withCtx(() => [
            _createVNode($setup["NIcon"], {
              size: "14",
              component: $setup.CopyIcon
            }, null, 8, ["component"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 3
  }, 8, ["trigger"]))
}