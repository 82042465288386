import { AxiosResponse } from "axios";
import ApiService from "@/services/index";

export default class CurrencyService {
  static getCurrencies(): Promise<AxiosResponse> {
    return ApiService.get("/v1/currencies");
  }
  static getExchangeOneRate(params: {
    symbol: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/exchange-rates/find-one", { params });
  }
}
