import { statusLabel } from "@/constants/customer/statusLabel";
import { CustomerStatusEnum } from "@/enums/customer/CustomerStatusEnum";

export const getCustomerStatus = (
  status: CustomerStatusEnum,
  isDeleted = false
): string => {
  if (isDeleted) {
    return "Deleted";
  }
  return statusLabel[status];
};
