const confirmQuoteError = (statuses: string): string => {
  return `To confirm quote, all parts must be in ${statuses} Status`;
};

export const quoteConfirmPartStatusError = confirmQuoteError(
  '"Confirmed", "Declined" Or "Authorization"'
);

export const quoteConfirmPartStatusAuthorizerError = confirmQuoteError(
  '"Confirmed" or "Declined"'
);
export const quoteEmptyPartQuantityError =
  "Please select quantity for each part";
