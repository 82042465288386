import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57161f03"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 3,
  class: "add-part-files-modal-parts"
}
const _hoisted_2 = {
  key: 0,
  class: "part-list-errors"
}
const _hoisted_3 = {
  key: 1,
  class: "part-list-errors"
}
const _hoisted_4 = { class: "scrollable" }
const _hoisted_5 = { class: "add-part-files-modal-actions main-modal-actions" }
const _hoisted_6 = { class: "popup-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-part-files-modal main-modal",
    preset: "dialog",
    "show-icon": false,
    title: 'Add parts'
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
      ($setup.showPartModelViewer)
        ? (_openBlock(), _createBlock($setup["PartModelViewer"], {
            key: 0,
            show: $setup.showPartModelViewer,
            "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showPartModelViewer) = $event)),
            item: $setup.partList[$setup.partModelViewIndex],
            onClose: $setup.onPartModelViewerClose
          }, null, 8, ["show", "item", "onClose"]))
        : _createCommentVNode("", true),
      ($setup.showAttachModelModal)
        ? (_openBlock(), _createBlock($setup["Attach3DModal"], {
            key: 1,
            show: $setup.showAttachModelModal,
            "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (($setup.showAttachModelModal) = $event)),
            part: $setup.partList[$setup.attachFilePartIndex],
            models: $props.additionalModels,
            onExtraModelsPicked: _cache[2] || (_cache[2] = ($event: any) => ($setup.onExtraFilesPicked($event, $setup.AdditionalFileType.Model))),
            on3dSelected: $setup.onModelSelected,
            onClose: _cache[3] || (_cache[3] = ($event: any) => ($setup.showAttachModelModal = false))
          }, null, 8, ["show", "part", "models"]))
        : _createCommentVNode("", true),
      ($setup.showAttachDrawingModal)
        ? (_openBlock(), _createBlock($setup["AttachDrawingModal"], {
            key: 2,
            show: $setup.showAttachDrawingModal,
            "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => (($setup.showAttachDrawingModal) = $event)),
            part: $setup.partList[$setup.attachFilePartIndex],
            drawings: $props.additionalDrawings,
            onExtraDrawingsPicked: _cache[5] || (_cache[5] = ($event: any) => ($setup.onExtraFilesPicked($event, $setup.AdditionalFileType.Drawing))),
            onDrawingSelected: $setup.onDrawingSelected,
            onClose: _cache[6] || (_cache[6] = ($event: any) => ($setup.showAttachDrawingModal = false))
          }, null, 8, ["show", "part", "drawings"]))
        : _createCommentVNode("", true),
      ($setup.partList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            ($setup.activeErrors)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.partsWithErrorsCountMessage), 1))
              : ($setup.duplicatedPartNumbersErrorCount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.duplicatedPartNumbersErrorCountMessage), 1))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.partList, (item, index) => {
                return (_openBlock(), _createBlock($setup["NewPartListItem"], {
                  key: item.id,
                  item: item,
                  index: index,
                  partOperationLoadingIndex: $setup.partOperationLoadingIndex,
                  dirty: $setup.dirty,
                  activeErrors: $setup.activeErrors,
                  isPreviewAvailable: $setup.isPreviewAvailable(item, true),
                  maxQuantitiesLength: $setup.maxQuantitiesLength,
                  doesPartNumberAlreadyExist: $setup.doesPartNumberAlreadyExist(item),
                  isPartNumberDuplicated: $setup.isPartNumberDuplicated(index),
                  onModelPreviewClicked: ($event: any) => ($setup.onModelPreviewClicked(item, index)),
                  onAttach3DClicked: $setup.onAttachModelClicked,
                  onAttachDrawingClicked: $setup.onAttachDrawingClick,
                  onPartNumberUpdated: $setup.onPartNumberUpdated,
                  onRetry3dUpload: $setup.onRetry3DUpload,
                  onRetryDrawingUpload: $setup.uploadDrawing,
                  onRemovePart: ($event: any) => ($setup.onRemovePart(index)),
                  onQuantityAdded: $setup.onQuantityAdded,
                  onRemoveQuantity: $setup.onRemoveQuantity,
                  onRemarkAdded: $setup.onRemarkAdded,
                  onRemoveRemark: $setup.onRemoveRemark
                }, null, 8, ["item", "index", "partOperationLoadingIndex", "dirty", "activeErrors", "isPreviewAvailable", "maxQuantitiesLength", "doesPartNumberAlreadyExist", "isPartNumberDuplicated", "onModelPreviewClicked", "onRemovePart"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["NButton"], {
          class: "medium-button submit-button",
          type: "info",
          disabled: $setup.isSaveDisabled,
          onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.onAddPartsClicked()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Add")
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode($setup["NButton"], {
          class: "medium-button n-button-cancel",
          type: "tertiary",
          disabled: $setup.loading,
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode($setup["UploadingFilesPopup"], {
          filesForUpload: $setup.filesForUpload,
          onSetToLoad: $setup.onSetToLoad,
          onStartedLoading: $setup.onStartedLoadingFile,
          onFinishedLoading: $setup.onFinishedLoadingEvent,
          onModelProcessingUpdate: $setup.onModelProcessingUpdate,
          onErrorLoading: $setup.onErrorLoadingFile,
          onUploadFilesFinished: $setup.onUploadFilesFinished
        }, null, 8, ["filesForUpload", "onSetToLoad"])
      ])
    ]),
    _: 1
  }))
}