import { ref, Ref } from "vue";
import { useStore } from "vuex";
import { LoadingBarInst } from "naive-ui/es/loading-bar/src/LoadingBarProvider";
import QuoteService from "@/services/QuoteService";
import TechnicalFeedbackInterface from "@/types/parts/TechnicalFeedbackInterface";

export default () => {
  const initialRfqPartFeedback = ref<TechnicalFeedbackInterface>({
    createdAt: "",
    drawings: [
      {
        id: "",
        link: "",
        filename: "",
      },
    ],
    id: "",
    messageCount: 0,
    messages: [
      {
        id: "",
        message: "",
        userId: "",
      },
    ],
    updatedAt: "",
    isQuoteDeleted: false,
  });
  const quoteStatus = ref<number>();
  const isQuoteDeleted = ref(false);

  const getTechnicalFeedback = (
    rfqPartId: string,
    rfqPartFeedback: Ref<TechnicalFeedbackInterface>,
    loading: Ref<boolean>,
    loadingBar: LoadingBarInst
  ) => {
    loading.value = true;
    loadingBar.start();
    return QuoteService.getTechnicalFeedback({ rfqPartId })
      .then((res) => {
        rfqPartFeedback.value = res.data.data;
        quoteStatus.value = rfqPartFeedback.value.quoteStatus;
        isQuoteDeleted.value = rfqPartFeedback.value.isQuoteDeleted;
      })
      .finally(() => {
        loading.value = false;
        loadingBar.finish();
      });
  };

  const messagesToUpload = ref<Array<string>>([]);
  const store = useStore();

  const addMessage = (
    feedback: string,
    rfqPartFeedback: Ref<TechnicalFeedbackInterface>
  ) => {
    if (feedback) {
      rfqPartFeedback.value?.messages.push({
        id: feedback,
        message: feedback,
        userId: store.getters["user/getUserId"],
      });
      messagesToUpload.value.push(feedback);
    }
  };

  const addTechnicalFeedback = (
    rfqPartFeedback: Ref<TechnicalFeedbackInterface>,
    loading?: Ref<boolean>,
    loadingBar?: LoadingBarInst
  ) => {
    if (!messagesToUpload.value.length || !rfqPartFeedback.value) {
      return Promise.resolve();
    }
    if (loading && loadingBar) {
      loading.value = true;
      loadingBar.start();
    }
    return QuoteService.addTechnicalFeedback({
      feedbackId: rfqPartFeedback.value?.id,
      messages: messagesToUpload.value,
    })
      .then((res) => {
        rfqPartFeedback.value.messageCount += res.data.data.length;
      })
      .finally(() => {
        if (loading && loadingBar) {
          loading.value = false;
          loadingBar.finish();
        }
      });
  };

  const removeTechnicalFeedback = (
    index: number,
    rfqPartFeedback: Ref<TechnicalFeedbackInterface>,
    loading: Ref<boolean>,
    loadingBar: LoadingBarInst
  ) => {
    if (rfqPartFeedback.value?.messages) {
      if (index <= rfqPartFeedback.value.messageCount - 1) {
        loading.value = true;
        loadingBar.start();
        return QuoteService.removeTechnicalFeedback(
          rfqPartFeedback.value.messages[index].id
        )
          .then(() => {
            rfqPartFeedback.value?.messages.splice(index, 1);
            rfqPartFeedback.value.messageCount--;
          })
          .finally(() => {
            loading.value = false;
            loadingBar.finish();
          });
      } else {
        rfqPartFeedback.value?.messages.splice(index, 1);
        messagesToUpload.value.splice(
          index - rfqPartFeedback.value.messageCount - 1,
          1
        );
        return Promise.reject();
      }
    } else {
      return Promise.reject();
    }
  };

  const addTechnicalFeedbackDrawing = (
    file: File[],
    rfqPartFeedback: Ref<TechnicalFeedbackInterface>,
    loading: Ref<boolean>,
    loadingBar: LoadingBarInst
  ) => {
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("feedbackId", rfqPartFeedback.value.id);
    if (!rfqPartFeedback.value) {
      return;
    }
    loading.value = true;
    loadingBar.start();
    QuoteService.addTechnicalFeedbackDrawing(formData)
      .then((res) => {
        rfqPartFeedback.value.drawings.push({
          id: res.data.data.id,
          link: res.data.data.link,
          filename: res.data.data.filename,
        });
      })
      .finally(() => {
        loading.value = false;
        loadingBar.finish();
      });
  };

  const removeTechnicalFeedbackDrawing = (
    rfqPartFeedback: Ref<TechnicalFeedbackInterface>,
    loading: Ref<boolean>,
    loadingBar: LoadingBarInst
  ) => {
    loading.value = true;
    loadingBar.start();
    QuoteService.removeTechnicalFeedbackDrawing({
      id: rfqPartFeedback.value.id,
      drawingId: rfqPartFeedback.value.drawings[0].id,
    })
      .then(() => {
        rfqPartFeedback.value.drawings.splice(0, 1);
      })
      .finally(() => {
        loading.value = false;
        loadingBar.finish();
      });
  };

  return {
    initialRfqPartFeedback,
    getTechnicalFeedback,
    addTechnicalFeedback,
    removeTechnicalFeedback,
    addTechnicalFeedbackDrawing,
    removeTechnicalFeedbackDrawing,
    addMessage,
    quoteStatus,
    isQuoteDeleted,
  };
};
