import { ref, watch } from "vue";
import CurrencyService from "@/services/CurrencyService";
import QuoteService from "@/services/QuoteService";
import CurrencyMenuInterface from "@/types/CurrencyMenuInterface";

export default () => {
  const currencies = ref<CurrencyMenuInterface[]>([]);
  const currencySign = ref<string>("USD");
  const currencyRate = ref<number>(1);
  const fetchCurrencies = () => {
    const currencyMenu = [] as CurrencyMenuInterface[];
    return CurrencyService.getCurrencies().then((res) => {
      res.data.data.forEach((item: CurrencyMenuInterface) =>
        currencyMenu.push({
          label: `${item.symbol} ${item?.sign}`,
          key: `currency ${item.id}`,
          currencyId: item.id,
          id: item.id,
          symbol: item.symbol,
          currencySymbol: item.symbol,
        })
      );
      currencies.value = currencyMenu;
    });
  };
  const onUpdateQuoteCurrency = (
    option: CurrencyMenuInterface,
    quoteId: string
  ) => {
    return QuoteService.changeQuoteCurrency(
      quoteId,
      option?.currencyId || option.id
    );
  };

  const getExchangeOneRate = () => {
    CurrencyService.getExchangeOneRate({ symbol: currencySign.value }).then(
      (res) => {
        currencyRate.value = +res.data.data.rate;
      }
    );
  };
  watch(
    () => currencySign.value,
    () => {
      getExchangeOneRate();
    }
  );
  return {
    currencies,
    fetchCurrencies,
    onUpdateQuoteCurrency,
    getExchangeOneRate,
  };
};
