import { ref } from "vue";
import downloadFileComposable from "@/composables/downloadFileComposable";
import FileService from "@/services/FileService";
import { FileUploadProcessingStatuses } from "@/enums/FileUploadProcessingStatuses";
const { downloadFile, downloadFileFromUrl } = downloadFileComposable();

export const fileIds = ref<Map<string, ReturnType<typeof setInterval>>>(
  new Map()
);

const PING_INTERVAL = 60 * 1000;
const MAX_PING_TIME = PING_INTERVAL * 20;

export const downloadFileWithInterval = (
  id: string,
  fileName: string
): void => {
  if (fileIds.value.has(id)) {
    return;
  }
  const downloadInterval = setInterval(() => {
    FileService.downloadFile(id).then((res) => {
      const { originalUploadId } = res.data.data;
      if (
        originalUploadId?.status === FileUploadProcessingStatuses.Done &&
        originalUploadId.link
      ) {
        deleteFileInterval(id);
        downloadFileFromUrl(originalUploadId.link, fileName);
      }
    });
  }, PING_INTERVAL);
  setTimeout(() => {
    deleteFileInterval(id);
  }, MAX_PING_TIME);
  fileIds.value.set(id, downloadInterval);
};

export const deleteFileInterval = (id: string) => {
  const interval = fileIds.value.get(id);
  if (interval) {
    clearInterval(interval);
    fileIds.value.delete(id);
  }
};
