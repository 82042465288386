import { createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b19e7930"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "new-member",
  ref: "newMemberWrapperRef"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "new-member--input-container" }
const _hoisted_4 = ["onMouseover", "onMousedown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["new-member--wrapper", {'new-member--wrapper-with-role': $props.showRole}])
    }, [
      _createVNode($setup["MagnifyingGlass"], {
        class: "new-member--icon",
        width: "11px"
      }),
      _createElementVNode("div", null, [
        ($props.showRole)
          ? (_openBlock(), _createBlock($setup["NDropdown"], {
              key: 0,
              class: "user-role-description-dropdown main-dropdown",
              options: $props.newRoleOptions,
              placement: "bottom",
              trigger: "click",
              "render-label": $setup.renderDropdownLabel,
              disabled: $props.disabledRoleOptions,
              onSelect: $setup.onSelectNewRole
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: _normalizeClass(["user-role", {'user-role-select': !$props.disabledRoleOptions}]),
                  onClick: _withModifiers($setup.closeList, ["prevent"])
                }, _toDisplayString($props.newRole), 11, _hoisted_2)
              ]),
              _: 1
            }, 8, ["options", "disabled"]))
          : _createCommentVNode("", true),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selectedMembers, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "new-member--tag-container",
              key: index
            }, [
              _createVNode($setup["FilterTag"], {
                class: "new-member--tag",
                value: item.label,
                onDeleteItem: ($event: any) => ($setup.removeSelectedMember(index))
              }, null, 8, ["value", "onDeleteItem"])
            ]))
          }), 128)),
          _createElementVNode("li", _hoisted_3, [
            _createVNode($setup["NInput"], {
              class: _normalizeClass(["input-borderless", {'with-placeholder': !$setup.selectedMembers.length}]),
              ref: "inputRef",
              value: $setup.inputValue,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.inputValue) = $event)),
              autosize: "",
              placeholder: $setup.selectedMembers.length ? '' : $props.placeholder,
              onFocus: $setup.openList,
              onInput: $setup.onInput,
              onKeydown: $setup.onKeydown
            }, null, 8, ["value", "class", "placeholder"])
          ])
        ])
      ])
    ], 2),
    ($setup.showList)
      ? (_openBlock(), _createBlock($setup["NScrollbar"], {
          key: 0,
          class: "new-member--block user-list--wrapper"
        }, {
          default: _withCtx(() => [
            _createElementVNode("ul", null, [
              (!$setup.displayedItems.length)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    class: _normalizeClass(["user-list--empty", {'clickable': $setup.isEmail($setup.inputValue)}]),
                    onMousedown: $setup.addUserByEmail
                  }, _toDisplayString($setup.emptyStateText), 35))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.displayedItems, (item, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: _normalizeClass(["user-list--item", {'user-list--item-active': index === $setup.activeIndex, 'user-list--item-selected': $setup.selectedValues.includes(item.value)}]),
                      key: item.key,
                      onMouseover: ($event: any) => ($setup.activeIndex = index),
                      onMousedown: ($event: any) => ($setup.selectUser(index))
                    }, [
                      _createVNode($setup["UserAvatar"], {
                        class: "user-list--avatar",
                        id: item.src,
                        size: 24,
                        name: item.label
                      }, null, 8, ["id", "name"]),
                      _createTextVNode(_toDisplayString(item.label), 1)
                    ], 42, _hoisted_4))
                  }), 128))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ])), [
    [$setup["vClickOutside"], $setup.closeList]
  ])
}