import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a01a630a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-item" }
const _hoisted_2 = { class: "member-info-wrapper" }
const _hoisted_3 = { class: "member-info" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = {
  key: 0,
  class: "role light"
}
const _hoisted_6 = {
  key: 1,
  class: "role"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["UserAvatar"], {
          id: $props.member.userId?.avatar,
          name: $props.member.userId?.firstName || $props.member.email,
          size: 32
        }, null, 8, ["id", "name"]),
        _createElementVNode("span", _hoisted_4, _toDisplayString($props.member.userId ? `${$props.member.userId?.firstName} ${$props.member.userId?.lastName}` : $props.member.email) + " " + _toDisplayString($props.member.userId?.id === $setup.userId ? "(You)" : ""), 1)
      ]),
      ($props.invited)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Invited"))
        : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($props.member.userId?.type ===  $setup.UserTypes.internal ? $props.member.userId?.position : $props.member.teamRole), 1))
    ]),
    ($props.showDeleteButton)
      ? (_openBlock(), _createBlock($setup["NButton"], {
          key: 0,
          class: _normalizeClass(["delete-button text-button", {hidden: !$props.isRemovable}]),
          tabindex: $props.isRemovable ? 0 : -1,
          text: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('removeMember')))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["NIcon"], {
              size: "10",
              component: $setup.CrossCloseThinIcon
            }, null, 8, ["component"])
          ]),
          _: 1
        }, 8, ["class", "tabindex"]))
      : _createCommentVNode("", true)
  ]))
}