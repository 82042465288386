import { AxiosResponse } from "axios";
import ApiService from "@/services";
import { TeamRoles } from "@/enums/TeamRoles";

export default class TeamService {
  static sendInvite(payload: {
    teamId: string;
    email?: string;
    userId?: string;
    teamRole: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/teams/invite", payload);
  }
  static deleteMember(memberId: string): Promise<AxiosResponse> {
    {
      return ApiService.delete(`/v1/teams/members/${memberId}`);
    }
  }
  static cancelInvite(inviteId: string): Promise<AxiosResponse> {
    {
      return ApiService.delete(`/v1/teams/invite/${inviteId}`);
    }
  }
  static acceptInvite(token: string): Promise<AxiosResponse> {
    return ApiService.post(`/v1/teams/invite/${token}/accept`);
  }
  static updateMember(
    memberId: string,
    teamRole: TeamRoles
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/teams/members/${memberId}`, { teamRole });
  }
}
