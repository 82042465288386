import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "technical-feedback-modal-actions main-modal-actions" }
const _hoisted_2 = {
  key: 0,
  class: "error-before-saving"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: _normalizeClass(["technical-feedback-modal", {'technical-feedback-modal-customer': $setup.isCustomerUserType}]),
    preset: "dialog",
    "show-icon": false,
    title: "Technical feedback"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
      _createVNode($setup["TechnicalFeedbackContent"], {
        rfqPartFeedback: $setup.rfqPartFeedback,
        initialLoading: $setup.initialLoading,
        uneditableQuote: $props.uneditableQuote,
        onAddFeedback: $setup.addItem,
        onRemoveFeedback: $setup.removeItem,
        onEnterFeedback: $setup.onEnterFeedback,
        onExtraDrawingsPicked: $setup.onAddTechnicalFeedbackDrawing,
        onRemoveDrawing: $setup.onRemoveDrawing
      }, null, 8, ["rfqPartFeedback", "initialLoading", "uneditableQuote"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["NButton"], {
          class: "save-button medium-button n-button-save",
          type: "info",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onSaveFeedback())),
          disabled: $setup.isMessagesError && !$setup.isEnterFeedback
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString((!($setup.isRfqAllowedAdminRole || $setup.isCustomerUserRole) || $props.uneditableQuote) ? "Ok" : "Save"), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      ($setup.isMessagesError && !$setup.isEnterFeedback)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Please enter at least one comment on the drawing"))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}