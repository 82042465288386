import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "invite-member-modal",
    preset: "dialog",
    "show-icon": false,
    title: $props.title
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $props.popupLoading }, null, 8, ["loading"]),
      _createVNode($setup["InviteMemberSection"], {
        title: $setup.getTitleByUserRole,
        members: $props.members,
        activeMembers: $props.activeCustomerMembers,
        invitedMembers: $props.invitedCustomerMembers,
        editAllowed: $props.editCustomersAllowed,
        quoteStatus: $props.quoteStatus,
        "check-owner": "",
        deleteOwnerAllowed: $props.deleteOwnerAllowed,
        onSendInvite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addNewMembers', $event))),
        onRemoveMember: $setup.onRemoveMember
      }, null, 8, ["title", "members", "activeMembers", "invitedMembers", "editAllowed", "quoteStatus", "deleteOwnerAllowed"]),
      ($props.editInternalMembersAllowed || $props.activeInternalMembers.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode($setup["NDivider"]),
            _createVNode($setup["InviteMemberSection"], {
              class: "machinix-section",
              title: 'Machinix',
              "is-show-select": $props.editInternalMembersAllowed,
              members: $props.internalMembers,
              activeMembers: $props.activeInternalMembers,
              invitedMembers: $props.invitedInternalMembers,
              editAllowed: $props.editInternalMembersAllowed,
              createExternalMembers: false,
              showRole: false,
              quoteStatus: $props.quoteStatus,
              onSendInvite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('addNewMembers', $event))),
              onRemoveMember: $setup.onRemoveMember
            }, null, 8, ["is-show-select", "members", "activeMembers", "invitedMembers", "editAllowed", "quoteStatus"])
          ], 64))
        : _createCommentVNode("", true),
      ($setup.isShowRemoveModal)
        ? (_openBlock(), _createBlock($setup["RemoveMemberModal"], {
            key: 1,
            show: $setup.isShowRemoveModal,
            "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (($setup.isShowRemoveModal) = $event)),
            description: $setup.modalDescription,
            onRemove: $setup.onConfirmRemoving,
            onClose: _cache[3] || (_cache[3] = ($event: any) => ($setup.isShowRemoveModal = false))
          }, null, 8, ["show", "description"]))
        : _createCommentVNode("", true),
      ($setup.isShowCancelInviteModal)
        ? (_openBlock(), _createBlock($setup["CancelInviteMemberModal"], {
            key: 2,
            show: $setup.isShowCancelInviteModal,
            "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => (($setup.isShowCancelInviteModal) = $event)),
            description: $setup.modalDescription,
            onCancelInvite: $setup.onConfirmRemoving,
            onClose: _cache[5] || (_cache[5] = ($event: any) => ($setup.isShowCancelInviteModal = false))
          }, null, 8, ["show", "description"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}