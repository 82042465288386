import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43d6beca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "cancel-invite-member-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "cancel-invite-member-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Cancel invitation"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString($props.description), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["NButton"], {
          class: "remove-button",
          type: "info",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onCancelInviteMember()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Yes")
          ]),
          _: 1
        }),
        _createVNode($setup["NButton"], {
          class: "cancel-button",
          type: "error",
          ghost: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("No")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}