import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d03e8be4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "technical-feedback"
}
const _hoisted_2 = {
  key: 2,
  class: "technical-feedback-drawing"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 3,
  class: "technical-feedback-drawing"
}
const _hoisted_5 = {
  key: 4,
  class: "technical-feedback-drawing-error"
}
const _hoisted_6 = {
  key: 5,
  class: "technical-feedback-items"
}
const _hoisted_7 = { class: "feedback-number" }
const _hoisted_8 = { class: "feedback-value" }
const _hoisted_9 = {
  key: 2,
  class: "technical-feedback-item new-feedback"
}
const _hoisted_10 = { class: "feedback-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.rfqPartFeedback)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.showAddDrawingsModal)
          ? (_openBlock(), _createBlock($setup["AddDrawingsModal"], {
              key: 0,
              show: $setup.showAddDrawingsModal,
              "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showAddDrawingsModal) = $event)),
              onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showAddDrawingsModal = false)),
              onExtraDrawingsPicked: $setup.onAddTechnicalFeedbackDrawing
            }, null, 8, ["show"]))
          : _createCommentVNode("", true),
        ($setup.isTechnicalFeedbackAllowedRole && !$props.uneditableQuote)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "upload-drawing",
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showAddDrawingsModal = true)),
              role: "button"
            }, "Upload drawing"))
          : _createCommentVNode("", true),
        ($props.rfqPartFeedback.drawings[0]?.filename)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createTextVNode("Drawing: "),
              _createElementVNode("a", {
                class: "technical-feedback-drawing-link",
                href: $props.rfqPartFeedback.drawings[0].link,
                target: "_blank"
              }, _toDisplayString($props.rfqPartFeedback.drawings[0].filename), 9, _hoisted_3),
              ($setup.isTechnicalFeedbackAllowedRole && !$props.uneditableQuote)
                ? (_openBlock(), _createBlock($setup["NIcon"], {
                    key: 0,
                    class: "remove-drawing",
                    size: "10",
                    color: $setup.colors.iconColor,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('removeDrawing'))),
                    role: "button"
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["CrossCloseThinIcon"])
                    ]),
                    _: 1
                  }, 8, ["color"]))
                : _createCommentVNode("", true)
            ]))
          : ($setup.isTechnicalFeedbackAllowedRole)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, " "))
            : _createCommentVNode("", true),
        ($setup.isShowDrawingError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Please choose only one PDF file"))
          : _createCommentVNode("", true),
        (!$props.initialLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              ($props.rfqPartFeedback.messages[0]?.message)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.rfqPartFeedback.messages, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "technical-feedback-item",
                      key: item.id
                    }, [
                      _createElementVNode("div", _hoisted_7, _toDisplayString(index + 1), 1),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(item.message), 1),
                      ($setup.isTechnicalFeedbackAllowedRole && item.message && $setup.isCurrentUserAddedInformation(item.userId) && !$props.uneditableQuote)
                        ? (_openBlock(), _createBlock($setup["NIcon"], {
                            key: 0,
                            class: "remove-item",
                            size: "10",
                            color: $setup.colors.iconColor,
                            onClick: ($event: any) => ($setup.removeItem(index)),
                            role: "button"
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["CrossCloseThinIcon"])
                            ]),
                            _: 2
                          }, 1032, ["color", "onClick"]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                : _createCommentVNode("", true),
              ($props.rfqPartFeedback.messageCount === 0 && $props.uneditableQuote)
                ? (_openBlock(), _createBlock($setup["NEmpty"], {
                    key: 1,
                    description: "No feedbacks"
                  }))
                : _createCommentVNode("", true),
              ($setup.isTechnicalFeedbackAllowedRole && !$props.uneditableQuote)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString($props.rfqPartFeedback.messages[0]?.message ? $props.rfqPartFeedback.messages.length + 1 : 1), 1),
                    _createVNode($setup["NInput"], {
                      class: "feedback-value",
                      value: $setup.feedbackValue,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.feedbackValue) = $event)),
                      type: "text",
                      placeholder: "Enter feedback",
                      onKeydown: _withKeys($setup.addItem, ["enter"]),
                      onBlur: $setup.addItem
                    }, null, 8, ["value", "onKeydown"])
                  ]))
                : _createCommentVNode("", true),
              ($setup.isTechnicalFeedbackAllowedRole && !$props.uneditableQuote)
                ? (_openBlock(), _createBlock($setup["NButton"], {
                    key: 3,
                    circle: "",
                    type: "info",
                    ghost: ""
                  }, {
                    icon: _withCtx(() => [
                      _createVNode($setup["NIcon"], { size: "15" }, {
                        default: _withCtx(() => [
                          _createVNode($setup["PlusIcon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}