export enum StickerLabelsEnum {
  DRAFT = "DRAFT",
  VIEWED = "VIEWED",
  NEW = "NEW",
  NEW_UPDATES = "NEW UPDATES",
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  HIDDEN = "HIDDEN",
  RESOLVED = "RESOLVED",
}
