import { CustomerStatusEnum } from "@/enums/customer/CustomerStatusEnum";

export const statusLabel = {
  [CustomerStatusEnum.SIGN_UP]: "Sign-up",
  [CustomerStatusEnum.REQUEST_FOR_QUOTE]: "RFQ",
  [CustomerStatusEnum.NEW]: "New",
  [CustomerStatusEnum.CREDIT_APPLICATION]: "Credit application",
  [CustomerStatusEnum.ACTIVE]: "Active",
  [CustomerStatusEnum.SUSPENDED]: "Suspended",
};
