import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea36b9f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "description-bold" }
const _hoisted_3 = { class: "main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "error-invite-modal default-modal",
    preset: "dialog",
    "show-icon": false,
    title: "User cannot be invited"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString($props.invitesWithError.join(", ")) + " ", 1),
        _createTextVNode("cannot be invited as it is already registered as a Member of another Company account. Please contact your Account Manager for assistance.")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["NButton"], {
          class: "button",
          type: "info",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Ok")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}