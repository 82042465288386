const tagProperties = [
  {
    key: "material",
    value: "Material",
    order: 1,
  },
  {
    key: "subTech",
    value: "Sub-Tech",
    order: 4,
  },
  {
    key: "accuracyLevel",
    value: "Accuracy Level",
  },
  {
    key: "specialProcess",
    value: "Special Process",
  },
  {
    key: "surfaceFinish",
    value: "Surface Finish",
  },
  {
    key: "surfaceTreatments",
    value: "Surface Treatment",
    order: 2,
  },
  {
    key: "complexity",
    value: "",
    order: 3,
  },
];

export default tagProperties;
